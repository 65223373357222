import { Box, Center, useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProfileByUsername, getProfilePicture, signIn } from '../Service/amplifyService';
import { getPaymentIntent } from '../Service/paymentService';
import { ProfileCard } from '../Component/ProfileCard';
import PaymentModal from '../Component/PaymentModal';
import { DataStore } from '@aws-amplify/datastore';
import { Hub } from 'aws-amplify';
import SuccessModal from '../Component/SuccessModal';


function ProfilePage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose,
  } = useDisclosure();
  const [isLoaded, setLoaded] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [amount, setAmount] = useState(10);
  const [paymentIntent, setPaymentIntent] = useState('');
  const [senderName, setSenderName] = useState('');
  const [senderMessage, setSenderMessage] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  const currency = 'usd';
  const url = new URL(window.location.href);

  useEffect(() => {
    signIn().then(r => {
    }).catch(e => {
    });
  }, []);

  const username = window.location.pathname.split('/')[1];

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    onOpen();
  }, [onOpen]);
  const handleSuccessfulClose = () => {
    onClose();
    onSuccessOpen();
    navigate(username, { replace: true });
  };

  useEffect(() => {
    const removeListener = Hub.listen('datastore', async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      console.log('DataStore event', event, data);

      if (event === 'ready') {
        getProfileByUsername(username)
          .then(data => {
              if (!data) {
                toast({
                  title: 'Profile not found.',
                  description: username + ' does not exist.',
                  status: 'error',
                  duration: null,
                  isClosable: false,
                });
                return;
              }

              if (data.banned) {
                toast({
                  title: 'User banned.',
                  description: username + ' is banned.',
                  status: 'error',
                  duration: null,
                  isClosable: false,
                });
                return;
              }

              getProfilePicture(data.user_id).then(url => {
                console.log(url);
                setProfilePicture(url);
                setProfile(data);
                setLoaded(true);
              }).catch(e => {
              });
            },
          )
          .catch(error => {
            toast({
              title: 'An error occurred.',
              description: 'Unable to load profile.',
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            console.error(error);
          });
      }
    });

    // Start the DataStore, this kicks-off the sync process.
    DataStore.start();

    return () => {
      removeListener();
    };
  }, [profile.user_id, toast, username]);

  useEffect(() => {
    getPaymentIntent(amount, currency)
      .then(value => {
        setButtonLoading(false);
        setPaymentIntent(value);
      }).catch(error => {
      console.log(error);
    });
  }, [amount, currency]);

  const handleAmountChange = (amount) => {
    setButtonLoading(true);
    setAmount(amount);

    getPaymentIntent(amount, currency)
      .then(value => {
          setButtonLoading(false);
          setPaymentIntent(value);
        },
      ).catch(error => {
      console.log(error);
    });
  };

  return (
    <Box
      w='100vw'
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      mt={2}
      mb={2}

    >
      <Center
      >
        <ProfileCard
          onOpen={onOpen}
          profile={profile}
          profilePicture={profilePicture}
          amount={amount}
          isButtonLoading={isButtonLoading}
          setSenderName={setSenderName}
          setSenderMessage={setSenderMessage}
          isLoaded={isLoaded}
          handleAmountChange={handleAmountChange}
        />
      </Center>
      <PaymentModal
        isOpen={isOpen}
        onClose={onClose}
        handleSuccessfulClose={handleSuccessfulClose}
        amount={amount}
        displayName={profile.display_name}
        stripePaymentIntent={paymentIntent}
        returnUrl={url.toString()}
        currency={currency}
        user_id={profile.user_id}
        name={senderName}
        message={senderMessage}
      />
      <SuccessModal
        isOpen={isSuccessOpen}
        onClose={onSuccessClose}
        amount={amount}
        profile={profile}
        profilePicture={profilePicture}
        pageUrl={url}
      />
    </Box>
  );
}

export default ProfilePage;
