import { DataStore } from '@aws-amplify/datastore';
import { Donation, Profile, Deduction, Statistic } from '../models';
import { Auth, Storage } from 'aws-amplify';
import { sendNotification } from './firebaseService';

DataStore.configure({
  syncEnabled: false,
});

export const getProfileByUsername = async (username) => {
  const profiles = await DataStore.query(Profile,
    (c) => c.user_name.eq(username));

  return profiles[0];
};

export const getProfileByUserId = async (userId) => {
  const profiles = await DataStore.query(Profile,
    (c) => c.user_id.eq(userId));

  return profiles[0];
};

export const addDonation = async (donation) => {
  await DataStore.save(
    donation,
  );
};

export const checkDonationExists = async (paymentIntentId) => {
  const donations = await DataStore.query(Donation,
    (c) => c.payment_intent_id.eq(paymentIntentId));

  return donations.length > 0;
};

export const checkDonationExistsByPaymentMethodId = async (paymentMethodId) => {
  const donations = await DataStore.query(Donation,
    (c) => c.payment_method_id.eq(paymentMethodId));

  return donations.length > 0;
};

export const updateProfileBalance = async (user_id, amount) => {
  let profile = await getProfileByUserId(user_id);

  let deductions = await DataStore.query(Deduction);

  let netAmount = amount;
  await updateStatistic('total-transactions', 1);
  await updateStatistic('gmv', amount);

  for (const deduction of deductions) {
    let totalDeduction = (amount * deduction.percent) + deduction.flat_amount;
    netAmount -= totalDeduction;
    await updateStatistic('total-revenue', totalDeduction);
  }

  await DataStore.save(
    Profile.copyOf(profile, updated => {
      updated.balance = updated.balance + netAmount;
    }));

  await sendNotification(profile.token, amount);
};

export const updateStatistic = async (code, value) => {
  const statistics = await DataStore.query(Statistic,
    (c) => c.code.eq(code));

  const statistic = statistics.length > 0 ? statistics[0] : null;

  if (statistic == null) {
    return false;
  }

  await DataStore.save(
    Statistic.copyOf(statistic, updated => {
      updated.value = statistic.value + value;
    }));

  return true;
};
export const signIn = async () => {
  try {
    await Auth.signIn(
      'guest@onlygifts.com', 'Password123@');
    console.log('success signing in');
  } catch (error) {
    console.log('error signing in', error);
  }
};

export const getProfilePicture = async (id) => {
  return await Storage.get(id, { level: 'public' });
};