import { Box, Button, Icon, Image, Input, Link, Skeleton, Text, Textarea } from '@chakra-ui/react';
import { FaLink } from 'react-icons/all';
import React from 'react';

export function ProfileCard({
                              onOpen,
                              isLoaded,
                              amount,
                              profile,
                              profilePicture,
                              isButtonLoading,
                              handleAmountChange,
                              setSenderName,
                              setSenderMessage,
                            }) {

  const shortenUrl = (url, maxLength = 30) => {
    let length = url?.length ?? 0;
    if (length <= maxLength) {
      return url;
    }

    return `${url.slice(0, maxLength)}...`;
  };

  return (
    <Box
      w={['360px', '360px', '400px', '500px']}
      p={4}
      backgroundColor='#F5F5F5'
      borderRadius='20px'
      display='flex'
      visibility={'visible'}
      justifyContent='center'
      flexDirection={'column'}
      alignItems='center'>
      <Skeleton
        isLoaded={isLoaded}
        borderRadius={'50%'}
      >
        <Image
          height={'150px'}
          width={'150px'}
          src={profilePicture}
          objectFit='cover'
          borderRadius='100%'
          bg='lightGrey' />
      </Skeleton>
      <Box h={'10px'} />
      <Skeleton
        isLoaded={isLoaded}
      >
        <Text
          fontSize={'20px'}
          color={'#4D4D4D'}
          fontWeight={'bold'}>
          {profile.display_name}
        </Text>
      </Skeleton>

      <Box h={'1px'} />
      <Skeleton
        isLoaded={isLoaded}
      >
        <Text
          fontSize={'16px'}
          fontWeight={'normal'}
          color={'#BBBBBB'}>
          @{profile.user_name}
        </Text>
      </Skeleton>
      <Box h={'5px'} />
      <Skeleton
        isLoaded={isLoaded}
      >
        <Text
          fontSize={'16px'}
          fontWeight={'normal'}
          color={'#4D4D4D'}
          textAlign={'center'}>
          {profile.bio}
        </Text>
      </Skeleton>

      <Box h={'5px'} />
      <Skeleton
        isLoaded={isLoaded}
      >
        {profile.url &&
          (<Box
            display='flex'
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            color={'#00afef'}
            w={'100%'}>
            <Icon as={FaLink} w={5} h={5} />
            <Box w={'5px'} />
            <Link
              fontSize={'16px'}
              fontWeight={'bold'}
              onClick={() => {
                window.open(profile.url, '_blank');
              }}
              textAlign={'center'}>
              {shortenUrl(profile.url)}
            </Link>
          </Box>)}

      </Skeleton>


      <Box h={'20px'} />

      <Box
        border={'1px solid lightGray'}
        borderRadius={'10px'}
        w={'100%'}
        p={'20px 10px 10px 10px'}
      >
        <Skeleton
          isLoaded={isLoaded}
        >
          <Text
            fontSize={'16px'}
            fontWeight={'bold'}
            color={'#4D4D4D'}
            textAlign={'center'}>
            Show support to <Text as={'span'} color={'#00afef'}>{profile.display_name}</Text>
          </Text>
        </Skeleton>
        <Box h={'20px'} />
        <Skeleton
          isLoaded={isLoaded}
        >
          <Box display='flex' flexDirection={'row'} justifyContent={'space-evenly'} w={'100%'}>
            <Button backgroundColor={'white'} color={'#00afef'} onClick={() => handleAmountChange(1)}>$1</Button>
            <Box w={'5px'} />
            <Button backgroundColor={'white'} color={'#00afef'} onClick={() => handleAmountChange(5)}>$5</Button>
            <Box w={'5px'} />

            <Button backgroundColor={'white'} color={'#00afef'} onClick={() => handleAmountChange(10)}>$10</Button>
            <Box w={'5px'} />

            <Button backgroundColor={'white'} color={'#00afef'} onClick={() => handleAmountChange(20)}>$20</Button>
            <Box w={'5px'} />

            <Button backgroundColor={'white'} color={'#00afef'} onClick={() => handleAmountChange(100)}>$100</Button>
          </Box>
        </Skeleton>
        <Box h={'10px'} />

        <Skeleton
          isLoaded={isLoaded}
          w={'100%'}
        >
          <Input borderRadius={'4px'} placeholder={'Name (optional)'}
                 onChange={(e) => setSenderName(e.target.value)}></Input>
        </Skeleton>
        <Box h={'10px'} />
        <Skeleton
          isLoaded={isLoaded}
          w={'100%'}
        >
          <Textarea borderRadius={'4px'} placeholder={'Message (optional)'}
                    onChange={(e) => setSenderMessage(e.target.value)}></Textarea>
        </Skeleton>

        <Box h={'30px'} />
        <Skeleton
          isLoaded={isLoaded}
          w={'100%'}
        >
          <Button onClick={onOpen} w={'100%'} backgroundColor={'#00afef'} color={'white'} isLoading={isButtonLoading}>Support
            USD ${amount}</Button>
        </Skeleton>
      </Box>

    </Box>
  );
}