import axios from 'axios';

export const getSeonFraudScore = async () => {
  let ip = await axios.get('https://api.ipify.org?format=json');
  ip = ip.data.ip;

  try {
    const response = await axios.post('https://4ur4lxvpnfop6qfqapoe4szws40sivhk.lambda-url.us-east-1.on.aws/', {
      ip: ip,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching SEON fraud score:', error);

    return -1;
  }
};
