import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements, CardElement,
} from '@stripe/react-stripe-js';
import { Box, Button } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import {
  addDonation,
  checkDonationExists,
  checkDonationExistsByPaymentMethodId,
  updateProfileBalance,
} from '../Service/amplifyService';
import { Donation, DonationStatus } from '../models';
import { getSeonFraudScore } from '../Service/seonService';

export default function CheckoutForm({
                                       returnUrl,
                                       amount,
                                       user_id,
                                       currency,
                                       message,
                                       name,
                                       onClose,
                                       stripePaymentIntent,
                                     }) {
  const stripe = useStripe();
  const toast = useToast();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          toast({
            title: 'Payment successful.',
            description: 'Your payment was successful.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          break;
        case 'processing':
          toast({
            title: 'Payment processing.',
            description: 'Your payment is processing.',
            status: 'info',
            duration: 9000,
            position: 'top',
            isClosable: true,
          });

          break;
        case 'requires_payment_method':
          toast({
            title: 'Payment failed.',
            description: 'Your payment failed.',
            status: 'error',
            duration: 9000,
            position: 'top',
            isClosable: true,
          });
          break;
        default:
          toast({
            title: 'Payment failed.',
            description: 'Your payment failed.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          break;
      }
    });
  }, [amount, currency, message, name, stripe, toast, user_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    await elements.submit();

    const seonScore = await getSeonFraudScore();

    console.log('seonScore', seonScore);

    if (seonScore > 5) {
      const response = await stripe.confirmCardPayment(
        stripePaymentIntent.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {},
          },
        },
      );
      if (response.error) {
        const { error } = response;
        if (error.type === 'card_error' || error.type === 'validation_error') {
          toast({
            title: 'Payment failed.',
            description: error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Payment failed.',
            description: 'Your payment failed.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        let paymentIntent = response.paymentIntent;
        checkDonationExists(paymentIntent.id).then(exists => {
          if (!exists) {
            addDonation(new Donation({
              'amount': amount.toString(),
              'currency': currency,
              'message': message,
              'name': name,
              'user_id': user_id,
              'payment_intent_id': stripePaymentIntent.id,
              'seon_score': seonScore,
              'status': DonationStatus.COMPLETED,
            })).then(r => {
              updateProfileBalance(user_id, amount);
            }).catch(
              e => {
              },
            );
          }
        });
        onClose();
      }
    } else {
      try {
        const paymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
          },
        );
        checkDonationExistsByPaymentMethodId(paymentMethod.paymentMethod.id).then(exists => {
          if (!exists) {
            addDonation(new Donation({
              'amount': amount.toString(),
              'currency': currency,
              'message': message,
              'name': name,
              'user_id': user_id,
              'payment_method_id': paymentMethod.paymentMethod.id,
              'payment_intent_id': stripePaymentIntent.id,
              'seon_score': seonScore,
              'status': DonationStatus.PENDING,
            })).then(r => {
            }).catch(
              e => {
              },
            );
          }
        });
      } catch (e) {
        console.log(e);
      }


      onClose();
    }

    setIsLoading(false);
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id='link-authentication-element'
        // onChange={(e) => setEmail(e.target.value)}
      />
      <Box h={'20px'} />
      <CardElement id='card-element' options={{ hidePostalCode: true }} />
      <Box h={'20px'} />
      <Button onClick={handleSubmit} isLoading={isLoading || !stripe || !elements} id='submit' width={'100%'}
              backgroundColor={'#00afef'} color={'white'}
              _hover={{ backgroundColor: '#00afef' }}
      >
        <span id='button-text'>
          {isLoading ? <div className='spinner' id='spinner'></div> : 'Pay now'}
        </span>
      </Button>
      <Box h={'10px'} />
    </form>
  );
}