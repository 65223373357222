import React from 'react';
import {
  ChakraProvider,
  
} from '@chakra-ui/react';
import theme from './theme';
import ProfilePage from './Page/ProfilePage';


function App() {
  return (
    <ChakraProvider theme={theme}>
      <ProfilePage />
    </ChakraProvider>
  );
}

export default App;
