import axios from 'axios';

export const sendNotification = async (deviceToken, amount) => {
  const url = 'https://fcm.googleapis.com/fcm/send';
  const serverKey = 'AAAAJBKYgbQ:APA91bHs91binTXJvop9aiag8M3jT4ITWvRYHNZPhNGrl-vqb1BIOU39-yL-RhOHCGnTCoiK-RNhOCTzaO6U2Ct8zEd0_8cy0_X1rmoc4NhgLyvS6GeOCtFMO2Df4VQeLmDKQi_mD3TO';

  axios({
    method: 'post',
    url: url,
    headers: {
      'Authorization': `key=${serverKey}`,
      'Content-Type': 'application/json',
    },
    data: {
      notification: {
        title: 'New Gift : $' + amount,
        body: 'You have received a gift of $' + amount,
      },
      to: deviceToken,
    },
  }).then(response => {
    console.log('Notification sent successfully', response);
  }).catch(error => {
    console.log('Error sending notification', error);
  });
};
