import { loadStripe } from '@stripe/stripe-js';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51MAImQKYDlvGcSrf5Y1DKf37rUo5kqaJz3HeFx2pfLam0tVT3tV5MYty7IOmbAhx7ItzkvaJA8cOg79PtQ4JENok00zy1fSRkm');


export default function PaymentModal({
                                       isOpen,
                                       onClose,
                                       handleSuccessfulClose,
                                       stripePaymentIntent,
                                       amount,
                                       displayName,
                                       returnUrl,
                                       user_id,
                                       currency,
                                       name,
                                       message,
                                     }) {

  const appearance = {
    theme: 'stripe',
  };

  let client_secret = stripePaymentIntent.client_secret;
  const options = {
    clientSecret: client_secret,
    appearance,
  };

  return (<Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay backdropFilter='blur(10px)' />
      <ModalContent ml={2} mr={2}>
        <ModalHeader>Give ${amount} to {displayName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {stripePaymentIntent.client_secret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                returnUrl={returnUrl}
                amount={amount}
                user_id={user_id}
                currency={currency}
                message={message}
                name={name}
                onClose={handleSuccessfulClose}
                stripePaymentIntent={stripePaymentIntent}
              />
            </Elements>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}