import { ColorModeScript } from '@chakra-ui/react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

Amplify.configure(awsExports);
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);


root.render(
  <>
    <Helmet>
      <title>OnlyGifts: Donate to Your Favourite Creators</title>
      <meta property='og:title' content='OnlyGifts: Donate to Your Favourite Creators' />
      <meta property='og:description'
            content='OnlyGifts is a platform that allows you to show your appreciation for creators by making a donation. Find your favourite creator and support their work today!' />
      <meta property='og:url' content='https://www.only.gift' />
      <meta property='og:image' content='https://www.onlygifts.com/images/og-image.jpg' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='OnlyGifts' />
    </Helmet>
    <ColorModeScript />
    <Router>
      <App />
    </Router>
  </>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register({ swUrl: '/custom-service-worker.js' });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
