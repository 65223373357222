import {
  Box,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  Absolute,
} from '@chakra-ui/react';
import {
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share';
import Confetti from 'react-dom-confetti';
import { useEffect, useState } from 'react';

export default function SuccessModal({ isOpen, onClose, profilePicture, profile, amount, pageUrl }) {

  const shareText = `I just donated $${amount} to ${profile.display_name} via OnlyGifts! Check it out: ${pageUrl}`;

  const [isPlaying, setPlaying] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setPlaying(prev => !prev);
      }, 2000);

      // Clear interval on component unmount
      return () => clearInterval(interval);
    }
  }, [isOpen]);

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 20,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay backdropFilter='blur(10px)' />
      <ModalContent ml={2} mr={2}>
        <ModalHeader
          textAlign={'center'}
          textColor={'#00afef'}
        >Thank you for your Support</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          textAlign={'center'}
          fontWeight={'bold'}
          fontSize={20}
        >
          <Center>
            <Image
              height={'100px'}
              width={'100px'}
              src={profilePicture}
              objectFit='cover'
              borderRadius='100%'
              bg='lightGrey' />
          </Center>
          <Box
            height={5}
          />

          ${amount} to {profile.display_name}
          <Box
            height={5}
          />

          <Center>
            <HStack spacing={5}>
              <TwitterShareButton url={shareText}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={shareText}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <FacebookShareButton url={shareText}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </HStack>
          </Center>
          <Box
            height={5}
          />
          <Box position='absolute' top={'10%'} left={'50%'}>
            <Confetti active={isPlaying} config={confettiConfig} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
