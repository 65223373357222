import axios from 'axios';

export const getPaymentIntent = async (amount, currency) => {
  const response = await axios.post(
    'https://dvdkgma6ycozpfc75z37qeuxve0iwufe.lambda-url.us-east-1.on.aws',
    {
      'amount': amount * 100,
      'currency': currency,
    },
  );

  return response.data;
};