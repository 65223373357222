/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://2ksl5ufvljgrvp2ha4wpskd3g4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qr5ydhxn4vah5l5rxsuy5gpsqm",
    "aws_cognito_identity_pool_id": "us-east-1:28f095ae-fd75-4ff1-a9f5-d571b3e037ab",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dK4ZHbocY",
    "aws_user_pools_web_client_id": "3m8r32mct7blaf3qjcqvl8so65",
    "oauth": {
        "domain": "l41nud5xbmcv-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "onlygifts://",
        "redirectSignOut": "onlygifts://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "onlygifts-storage-a05dc740215045-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
